@import 'bootstrap';
@import "~@fortawesome/fontawesome-svg-core/styles.css";

:root {
  --fc-bg-event-color: #6417fb;
  --fc-bg-event-opacity: 0.3;
  --bs-font-sans-serif: var(--font-main);
  --bs-font-monospace: var(--font-monospace);
}

html {
  height: 100%;
}

body {
  height: 100%;
  overflow-y: scroll;
}

$calendar-available-days-color: #a1e4a1;
$calendar-available-days-opacity: 1;

.calendar-legend {
  width: 20px;
  height: 20px;
  border: 1px solid $gray-500;
  position: relative;
}

.fc {
  .fc-bg-success {
    background-color: $calendar-available-days-color;
    opacity: $calendar-available-days-opacity;
  }

  .fc-view {
    border: 0;


    a {
      text-decoration: none;
      color: $body-color;
    }

    .fc-daygrid-day-frame {
      display: flex;
      flex-direction: column-reverse;
    }

    .fc-bg-event {
      .fc-event-title {
        color: white;
      }
    }
  }
}

.fw-medium {
  font-weight: 500;
}
$white: #ffffff;
$success: #0fd179;

$theme-colors: (
        "light": #fdf3db,
        "dark": #101255,
        "primary": #4a33b3,
        "secondary": #0030e1,
        "info": rgba(0, 107, 255, 0.5),
        "success": $success,
        "warning": #e5d015,
        "danger": #f62609,
);